import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { APP_BAR_DESKTOP, APP_BAR_MOBILE } from 'constants';
import { withStyles } from '@mui/styles';
import { Drawer, CircularProgress } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar, Topbar } from './components';
import styles from './styles';
import { LEFT_SIDEBAR_WIDTH } from '../../constants';
import { setSidebarIsOpen } from '../../redux/slices/common';

const MainStyle = styled('div')(({ theme }) => ({
	flexGrow: 1,
	minHeight: '100%',
	paddingTop: APP_BAR_DESKTOP,
	[theme.breakpoints.down('sm')]: {
		paddingTop: APP_BAR_MOBILE
	}
}));

function Dashboard({ classes, isLoading }) {
	const dispatch = useDispatch();
	const sidebarIsOpen = useSelector(state => state.common.sidebarIsOpen);
	const isMobile = useSelector(state => state.common.isMobile);

	useEffect(() => {
		if (isMobile) {
			dispatch(setSidebarIsOpen(false));
		} else {
			dispatch(setSidebarIsOpen(true));
		}
	}, [isMobile]);

	const handleClose = () => {
		dispatch(setSidebarIsOpen(false));
	};

	const handleToggleOpen = () => {
		dispatch(setSidebarIsOpen(!sidebarIsOpen));
	};

	const leftShift = sidebarIsOpen && !isMobile;

	const renderSpinner = () => {
		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress />
				</div>
			);
		}

		return (
			<MainStyle>
				<Outlet />
			</MainStyle>
		);
	};

	return (
		<>
			<Topbar isMobile={isMobile} classes={classes} />
			<Drawer
				anchor="left"
				classes={{ paper: classes.drawerPaper }}
				onClose={handleClose}
				open={sidebarIsOpen}
				variant="persistent"
				style={{ zIndex: 1100 }}
				PaperProps={{
					sx: {
						width: LEFT_SIDEBAR_WIDTH
					}
				}}
			>
				<Sidebar
					className={classes.sidebar}
					open={sidebarIsOpen}
					onToggleSidebar={handleToggleOpen}
				/>
			</Drawer>
			<main className={`${classes.content} ${leftShift && classes.leftContentShift}`}>
				{renderSpinner()}
			</main>
		</>
	);
}

export default withStyles(styles)(Dashboard);
